import * as React from 'react';

function MascotSittingIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 140 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1189_19788)">
        <path
          d="M95.5258 122.218V133.765C95.5258 139.671 90.7406 144.456 84.834 144.456"
          stroke="#A3A7CF"
          strokeWidth="23.8707"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.4379 122.218V133.765C50.4379 139.671 45.6527 144.456 39.7461 144.456"
          stroke="#A3A7CF"
          strokeWidth="23.8707"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M11.3135 36.46H10.6696C8.10035 36.46 6.01758 38.5427 6.01758 41.112V78.2169C6.01758 80.7862 8.10035 82.869 10.6696 82.869H11.3135C13.8828 82.869 15.9655 80.7862 15.9655 78.2169V41.112C15.9655 38.5427 13.8828 36.46 11.3135 36.46Z"
          fill="#E6E7F1"
        />
        <path
          d="M135.296 36.46H134.652C132.083 36.46 130 38.5427 130 41.112V78.2169C130 80.7862 132.083 82.869 134.652 82.869H135.296C137.865 82.869 139.948 80.7862 139.948 78.2169V41.112C139.948 38.5427 137.865 36.46 135.296 36.46Z"
          fill="#E6E7F1"
        />
        <path
          d="M96.1931 -0.000488281H43.1558C21.1855 -0.000488281 3.375 17.8099 3.375 39.7802V92.8175C3.375 114.788 21.1855 132.598 43.1558 132.598H96.1931C118.163 132.598 135.974 114.788 135.974 92.8175V39.7802C135.974 17.8099 118.163 -0.000488281 96.1931 -0.000488281Z"
          fill="#E6E7F1"
        />
        <path
          d="M89.5642 102.766H36.5158C16.4089 102.766 0.0546875 86.4119 0.0546875 66.305V39.7809C0.0546875 19.674 16.4089 3.31982 36.5158 3.31982H89.5531C109.66 3.31982 126.014 19.674 126.014 39.7809V66.305C126.014 86.4119 109.66 102.766 89.5531 102.766H89.5642Z"
          fill="#E6E7F1"
        />
        <path
          d="M89.5629 6.62842H36.5144C18.2062 6.62842 3.37305 21.4726 3.37305 39.7809V66.3051C3.37305 84.6133 18.2173 99.4575 36.5255 99.4575H89.5629C107.871 99.4575 122.715 84.6133 122.715 66.3051V39.7809C122.715 21.4726 107.871 6.62842 89.5629 6.62842Z"
          fill="#C1C3DD"
        />
        <path
          d="M89.5654 13.2563H36.5281C21.8792 13.2563 10.0039 25.1316 10.0039 39.7805V66.2936C10.0039 80.9425 21.8792 92.8178 36.5281 92.8178H89.5654C104.214 92.8178 116.09 80.9425 116.09 66.2936V39.7805C116.09 25.1316 104.214 13.2563 89.5654 13.2563Z"
          fill="#262A4B"
        />
        <path
          d="M17.2951 114.034C19.4902 114.034 21.2698 112.255 21.2698 110.06C21.2698 107.865 19.4902 106.085 17.2951 106.085C15.0999 106.085 13.3203 107.865 13.3203 110.06C13.3203 112.255 15.0999 114.034 17.2951 114.034Z"
          fill="#4FD95B"
        />
        <path
          d="M34.5267 119.341C38.1874 119.341 41.155 116.374 41.155 112.713C41.155 109.053 38.1874 106.085 34.5267 106.085C30.866 106.085 27.8984 109.053 27.8984 112.713C27.8984 116.374 30.866 119.341 34.5267 119.341Z"
          fill="#A3A7CF"
        />
        <path
          d="M54.4232 119.341C58.0839 119.341 61.0515 116.374 61.0515 112.713C61.0515 109.053 58.0839 106.085 54.4232 106.085C50.7625 106.085 47.7949 109.053 47.7949 112.713C47.7949 116.374 50.7625 119.341 54.4232 119.341Z"
          fill="#A3A7CF"
        />
        <path
          d="M89.5629 6.62842H36.5144C18.2062 6.62842 3.37305 21.4726 3.37305 39.7809V53.0374H10.0013V39.7809C10.0013 25.1365 21.87 13.2567 36.5255 13.2567H89.5629C104.207 13.2567 116.087 25.1254 116.087 39.7809V53.0374H122.715V39.7809C122.715 21.4726 107.871 6.62842 89.5629 6.62842Z"
          fill="#A3A7CF"
        />
        <path
          d="M39.746 144.456H25.0684"
          stroke="#A3A7CF"
          strokeWidth="23.8707"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M84.8339 144.456H70.1562"
          stroke="#A3A7CF"
          strokeWidth="23.8707"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M25.0684 134.52V144.456"
          stroke="#A3A7CF"
          strokeWidth="23.8707"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M70.1562 134.52V144.456"
          stroke="#A3A7CF"
          strokeWidth="23.8707"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M33.2063 33.6406C28.299 33.6406 24.3242 37.6154 24.3242 42.5227V48.4404H42.0884V42.5227C42.0884 37.6154 38.1137 33.6406 33.2063 33.6406Z"
          fill="#00C5FF"
        />
        <path
          d="M86.2454 33.6406C81.338 33.6406 77.3633 37.6154 77.3633 42.5227V48.4404H95.1275V42.5227C95.1275 37.6154 91.1528 33.6406 86.2454 33.6406Z"
          fill="#00C5FF"
        />
        <path
          d="M73.0428 70.6128H46.3965V77.2744H73.0428V70.6128Z"
          fill="#00C5FF"
        />
        <path
          d="M79.7039 63.9956H39.7344V70.6572H79.7039V63.9956Z"
          fill="#00C5FF"
        />
        <path
          d="M79.7039 57.334H39.7344V63.9956H79.7039V57.334Z"
          fill="#00C5FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1189_19788">
          <rect width="140" height="156" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MascotSittingIconComponent;
